import {
  Box,
  Flex,
  Input,
  PrimaryButton,
  SecondaryButton,
  SegmentedControl,
  system,
  SystemProps,
  Text,
} from "flicket-ui";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import useSWR from "swr";
import { Divider, Select } from "~components";
import { useReleases } from "~graphql";
import { useSDK } from "~hooks";
import { showErrorToast } from "~lib";

const StyledLabel = styled.label<SystemProps>`
  padding-bottom: 8px;

  color: ${(p) => p.theme.colors.N600};
  font-size: ${(p) => p.theme.fontSizes[3]};
  font-weight: ${(p) => p.theme.fontWeights.extraBold};
  letter-spacing: -0.165px;

  ${system}
`;

export const suggestedLinkTypes = [
  "access-your-tickets",
  "event-information",
  "event-registration",
  "event-tickets",
  "membership-renewal",
  "event-ticket-resale",
  "event-membership",
] as const;

export type SuggestedLinkType = typeof suggestedLinkTypes[number];

type ModalType = "link" | "button";
type ModalOption = {
  type: ModalType;
  label: string;
};

const modalOptions: ModalOption[] = [
  {
    type: "link",
    label: "Text link",
  },
  {
    type: "button",
    label: "Button",
  },
];

export type InsertButtonOrLinkOnSuccessOptions = {
  type: ModalType;
  url?: string;
  content?: string;
  suggestedLink?: SuggestedLinkType;
  eventId?: string;
  releaseId?: string;
};

type InsertModalContentProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isEmail: boolean;
  isEdit?: boolean;
  defaultValues?: {
    url?: string;
    content?: string;
    suggestedLink?: SuggestedLinkType;
    type?: ModalType;
    selectEvents?: boolean;
    eventId?: string;
    releaseId?: string;
  };
  onSuccess: ({
    url,
    content,
    suggestedLink,
    type,
    eventId,
    releaseId,
  }: InsertButtonOrLinkOnSuccessOptions) => void;
};

export const headerTextMap: Record<SuggestedLinkType, string> = {
  "access-your-tickets": "Link to their tickets",
  "event-information": "Link to event page",
  "event-registration": "Link to event registration",
  "event-tickets": "Link to tickets page",
  "event-ticket-resale": "Link to tickets page",
  "membership-renewal": "Link to membership renewal",
  "event-membership": "Link to ticketing page",
};

export const getHeaderText = ({
  suggestedLink,
  type,
  isEdit,
}: {
  suggestedLink?: SuggestedLinkType | undefined;
  type: ModalType;
  isEdit: boolean;
}) => {
  const text = suggestedLink ? headerTextMap[suggestedLink] : undefined;
  if (!suggestedLink) {
    let prefix = "Insert",
      suffix = "link";
    if (isEdit) {
      prefix = "Edit";
    }
    if (type === "button") {
      suffix = "button";
    }
    return `${prefix} ${suffix}`;
  }

  return isEdit ? `Edit ${text.toLowerCase()}` : text;
};

export const InsertActionButtons = ({
  setIsOpen,
  content,
  onSuccess,
  suggestedLink,
  url,
  type,
  eventId,
  releaseId,
  shouldLoadEvents,
}) => {
  return (
    <Flex justifyContent={"flex-end"} mt={3}>
      <SecondaryButton mr={3} onClick={() => setIsOpen(false)}>
        Cancel
      </SecondaryButton>
      <PrimaryButton
        onClick={() => {
          if (!content) {
            showErrorToast("Please fill in display text.");
          } else if (!url && !suggestedLink) {
            showErrorToast("Please fill in URL.");
          } else if (shouldLoadEvents && !eventId) {
            showErrorToast("Please select event.");
          } else {
            onSuccess({
              url,
              content,
              suggestedLink,
              type,
              eventId,
              releaseId,
            });
            setIsOpen(false);
          }
        }}
      >
        Apply
      </PrimaryButton>
    </Flex>
  );
};

export const InsertModalContent = ({
  setIsOpen,
  isEmail,
  isEdit,
  defaultValues,
  onSuccess,
}: InsertModalContentProps) => {
  const [url, setUrl] = useState(defaultValues?.url ?? "");
  const [content, setContent] = useState(defaultValues?.content ?? "");
  const suggestedLink = defaultValues?.suggestedLink;
  const [type, setType] = useState<ModalType>(defaultValues?.type ?? "link");
  const [eventId, setEventId] = useState<string | undefined>(
    defaultValues?.eventId
  );
  const [releaseId, setReleaseId] = useState<string | undefined>(
    defaultValues?.releaseId
  );
  const sdk = useSDK();

  const headerText = getHeaderText({
    type,
    isEdit,
    suggestedLink,
  });

  const shouldLoadEvents =
    suggestedLink && (defaultValues?.selectEvents || defaultValues?.eventId);

  const { data: paramsEvents } = shouldLoadEvents
    ? useSWR("paramsEvents", async () =>
        sdk
          .paramsEvents({
            where: {
              startDate: new Date().toISOString(),
            },
          })
          .then((res) => res?.events?.edges?.map(({ node }) => node))
      )
    : { data: null };

  const { data: releaseData } = useReleases(
    suggestedLink == "event-tickets" ? eventId : null
  );

  return (
    <>
      <Box d="flex" alignItems="center">
        <Text fontWeight="heavy" fontSize={6}>
          {headerText}
        </Text>
      </Box>
      <Divider />
      {paramsEvents?.length ? (
        <Select
          options={paramsEvents
            ?.filter((event) => {
              if (suggestedLink === "event-registration") {
                return event.enableWaitlist;
              }
              return true;
            })
            .map((event) => ({
              label: event.title,
              value: event.id,
            }))}
          defaultValue={defaultValues?.eventId}
          label={"Event"}
          onChange={setEventId}
          mt={4}
        />
      ) : null}
      {suggestedLink === "event-registration" && (
        <Text fontSize={2} color="N600" mt={1}>
          Only showing events with registration enabled.
        </Text>
      )}
      {releaseData?.length ? (
        <Select
          options={releaseData?.map((release) => ({
            label: release.name,
            value: release.id,
          }))}
          defaultValue={defaultValues?.releaseId}
          label={"Release"}
          onChange={setReleaseId}
          mt={4}
        />
      ) : null}
      {isEmail && !isEdit && (
        <Box mt={4}>
          <StyledLabel>Display as</StyledLabel>
          <SegmentedControl
            mt={1}
            value={type}
            onChange={(type) => setType(type as ModalType)}
            data={modalOptions.map(({ label, type }) => ({
              label,
              value: type,
            }))}
          />
        </Box>
      )}

      <Input
        label="Display text"
        autoFocus
        value={content}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setContent(e.target.value)
        }
        mt={4}
      />

      {!suggestedLink && (
        <Input
          mt={3}
          label={"URL"}
          value={url}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setUrl(e.target.value)
          }
        />
      )}

      <InsertActionButtons
        content={content}
        setIsOpen={setIsOpen}
        onSuccess={onSuccess}
        url={url}
        suggestedLink={suggestedLink}
        type={type}
        eventId={eventId}
        releaseId={releaseId}
        shouldLoadEvents={shouldLoadEvents}
      />
    </>
  );
};
