import { Attributes, CSSProperties } from "react";
import { Box, Text } from "flicket-ui";
import { Editor, Element, Range, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import {
  ReactEditor,
  RenderElementProps,
  useEditor,
  useFocused,
  useSelected,
} from "slate-react";
import { useTheme } from "styled-components";
import { Icon } from "../Icon";
import { Button } from "./components";
import isEqual from "lodash/isEqual";
import { topOfDocumentSelection } from "./util";
import { Trash } from "@phosphor-icons/react";
import { VideoPlayer } from "../ReactPlayer";

export interface VideoElement extends Element {
  type: "video";
  url: string;
}

export const insertVideo = (editor: ReactEditor, video: VideoElement) => {
  let blurSelection = (editor.blurSelection as any) as Range;

  if (!blurSelection) {
    blurSelection = topOfDocumentSelection;
  }

  Transforms.insertNodes(editor, video, {
    at: blurSelection,
  });

  // add 1 to focus on the video
  const newRange: Range = {
    anchor: {
      offset: 0,
      path: [blurSelection?.anchor.path[0] + 1, 0],
    },
    focus: {
      offset: 0,
      path: [blurSelection?.focus.path[0] + 1, 0],
    },
  };

  // If this is at the top of the document
  if (isEqual(blurSelection, topOfDocumentSelection)) {
    const node = editor.children[blurSelection.anchor.path[0]];
    if (node.type === "paragraph" && node.children[0].text === "") {
      Transforms.removeNodes(editor, {
        at: blurSelection,
      });
      Transforms.insertNodes(
        editor,
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
        {
          at: blurSelection,
        }
      );
    }
    editor.selection = blurSelection;
  } else {
    editor.selection = newRange;
  }
  // add a paragraph so the user can edit access the next line in the editor
  Transforms.insertNodes(editor, [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);
  ReactEditor.focus(editor);
};

export const MediaDeleteButton = ({ label }: { label: string }) => {
  const editor = useEditor();

  return (
    <Button
      onClick={() => {
        editor.deleteBackward("block");
      }}
    >
      <Icon icon={<Trash />} mr={"1/2"} />
      <Text variant="regular">{label}</Text>
    </Button>
  );
};

export const withVideo = (editor: Editor & ReactEditor & HistoryEditor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "video" ? true : isVoid(element);
  };

  return editor;
};

export const VideoComponent = ({
  attributes,
  children,
  element,
  style,
}: RenderElementProps & { style?: CSSProperties; element: VideoElement }) => {
  const selected = useSelected();
  const focused = useFocused();
  const theme = useTheme();

  const videoWrapper: Attributes["css"] = {
    ...style,
    position: "relative",
    paddingBottom: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
    width: "100%",
    height: 0,
    borderRadius: 4,
    overflow: "hidden",
    boxShadow: selected && focused ? `0 0 0 2px ${theme.colors.P300}` : "none",
  };

  const videoStyle: Attributes["css"] = {
    position: "absolute",
    top: 0,
    left: 0,
  };

  return (
    <Box {...attributes} style={videoWrapper} cursor="pointer">
      {/* children must be passed even not used https://github.com/ianstormtaylor/slate/issues/3930 */}
      {children}
      <Box contentEditable={false} pointerEvents="none">
        <VideoPlayer
          url={element.url}
          style={videoStyle}
          width="100%"
          height="100%"
        />
      </Box>
    </Box>
  );
};
