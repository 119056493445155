import { useAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
import { ReactEditor, useSlate } from "slate-react";
import Modal from "~components/common/CustomModal/CustomModal";
import { Transforms, Range } from "slate";
import { insertVideo, VideoElement } from "../video";
import InsertVideoForm, {
  FormValues,
  InsertVideoFormProps,
} from "./InsertVideoForm";

interface InsertVideoModalProps extends InsertVideoFormProps {
  isOpen: boolean;
}

export const videoModalState = atomWithReset<{
  isOpen: boolean;
  video: VideoElement;
}>({
  isOpen: false,
  video: null,
});

function InsertVideoModal(props: InsertVideoModalProps) {
  const { isOpen, close, onSubmit, afterSubmit, video } = props;

  return (
    <Modal isOpen={isOpen} close={close} small>
      {isOpen && (
        <InsertVideoForm
          close={close}
          onSubmit={onSubmit}
          video={video}
          afterSubmit={afterSubmit}
        />
      )}
    </Modal>
  );
}
const editVideo = (editor: ReactEditor, newVideoNode: VideoElement) => {
  const blurSelection = (editor.blurSelection as any) as Range;
  if (!blurSelection) {
    console.error("Cannot edit link without selection.");
    return;
  }

  Transforms.setNodes(editor, newVideoNode, {
    at: blurSelection,
    match: (n) => n.type === "video",
    mode: "lowest",
  });

  // refocus
  // https://github.com/ianstormtaylor/slate/issues/3412#issuecomment-663906003
  editor.selection = blurSelection;
  ReactEditor.focus(editor);
};

export default function SlateInsertVideoModal() {
  const [modalState] = useAtom(videoModalState);
  const resetModalState = useResetAtom(videoModalState);

  const editor = useSlate();

  const close = () => {
    resetModalState();
  };

  const afterSubmit = close;

  const onSubmit = async (formValues: FormValues) => {
    const { url } = formValues;

    const newVideo: VideoElement = {
      type: "video",
      children: [],
      url,
    };

    insertVideo(editor, newVideo);

    close();
  };

  return (
    <InsertVideoModal
      video={modalState.video}
      isOpen={modalState.isOpen}
      close={close}
      afterSubmit={afterSubmit}
      onSubmit={onSubmit}
    />
  );
}
