import { useRouter } from "next/router";
import { omit } from "lodash";
import {
  SECONDARY_NAVIGATION_INDEX,
  SECONDARY_NAVIGATION_KEYS,
  secondaryNavigationConfig,
} from "./secondary.config";
import {
  PRIMARY_NAVIGATION_KEYS,
  primaryNavigationConfig,
} from "./primary.config";
import { removeUndefined } from "~lib/helpers/removeUndefined";
import { useMemo } from "react";

export type SpecificEventSourceSelection = {
  eventId: string;
};

export type EventSourceSelection = SpecificEventSourceSelection | "all-events";

export type SpecificMembershipSourceSelection = {
  membershipId: string;
};

export type MembershipSourceSelection =
  | SpecificMembershipSourceSelection
  | "all-memberships";

export type SpecificSeasonSourceSelection = {
  seasonId: string;
};

export type SeasonSourceSelection =
  | SpecificSeasonSourceSelection
  | "all-seasons";

export type SourceSelection =
  | EventSourceSelection
  | MembershipSourceSelection
  | SeasonSourceSelection
  | "default";

export function isEventSource(
  sourceSelection: SourceSelection
): sourceSelection is EventSourceSelection {
  if (typeof sourceSelection === "string") {
    return sourceSelection === "all-events";
  }
  return "eventId" in sourceSelection;
}

export function isMembershipSource(
  sourceSelection: SourceSelection
): sourceSelection is EventSourceSelection {
  if (typeof sourceSelection === "string") {
    return sourceSelection === "all-memberships";
  }
  return "membershipId" in sourceSelection;
}

export function isSeasonSource(
  sourceSelection: SourceSelection
): sourceSelection is EventSourceSelection {
  if (typeof sourceSelection === "string") {
    return sourceSelection === "all-seasons";
  }
  return "seasonId" in sourceSelection;
}

export default function useNavigation() {
  const router = useRouter();
  const primaryNavSlug = router.query?.slug?.[0];

  const _selectedSource = (router.query.sourceSelection
    ? JSON.parse(decodeURIComponent(router.query.sourceSelection as string))
    : "default") as SourceSelection;

  // Memoize the selected source to prevent extra re-renders if the source didn't actually change.
  const selectedSource = useMemo(() => _selectedSource, [
    typeof _selectedSource === "string"
      ? _selectedSource
      : "membershipId" in _selectedSource
      ? _selectedSource.membershipId
      : "eventId" in _selectedSource
      ? _selectedSource.eventId
      : _selectedSource.seasonId,
  ]);

  /* --------------------------- Primary Navigation --------------------------- */
  const primaryRoute = Object.values(primaryNavigationConfig).find(
    (route) => route.slugSegment === primaryNavSlug
  );

  /* -------------------------- Secondary Navigation -------------------------- */
  const secondaryNavSlug = router.query?.slug?.[SECONDARY_NAVIGATION_INDEX];
  const activeSecondaryTab = Object.values(secondaryNavigationConfig).find(
    (conf) => conf.slugSegment === secondaryNavSlug
  );

  const secondaryNavigateTo = (key: SECONDARY_NAVIGATION_KEYS) => {
    const slugSegment = secondaryNavigationConfig[key].slugSegment;

    void router
      .push({
        pathname: `/reporting/${primaryNavSlug}/${slugSegment}`,
        query: omit(router.query, "slug"),
      })
      .then(() => {
        document.getElementById("layout-content").scrollTo(0, 0);
      });
  };

  /* ---------------------------- Set Selected Source --------------------------- */
  const setSelectedSource = (sourceSelection: SourceSelection) => {
    let primarySlugSegment: string;
    if (isEventSource(sourceSelection)) {
      primarySlugSegment =
        primaryNavigationConfig[PRIMARY_NAVIGATION_KEYS.EVENTS].slugSegment;
    } else if (isMembershipSource(sourceSelection)) {
      primarySlugSegment =
        primaryNavigationConfig[PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS]
          .slugSegment;
    } else if (isSeasonSource(sourceSelection)) {
      primarySlugSegment =
        primaryNavigationConfig[PRIMARY_NAVIGATION_KEYS.SEASONS].slugSegment;
    } else {
      primarySlugSegment =
        primaryNavigationConfig[PRIMARY_NAVIGATION_KEYS.EVENTS].slugSegment;
    }

    const pathname = `/reporting/${primarySlugSegment}/${secondaryNavSlug}`;

    const newQuery = removeUndefined({
      ...router.query,
      sourceSelection: encodeURIComponent(JSON.stringify(sourceSelection)),
      releaseId: undefined,
      channel: undefined,
    });
    void router.replace({
      pathname: pathname,
      query: newQuery,
    });
  };

  /* ------------------------------ Return value ------------------------------ */
  // TODO: Ideally these could be intercepted before this point so that there is ALWAYS a primary and secondary route and we
  // don't need the optional checks.
  return {
    selectedSource,
    setSelectedSource,
    activePrimaryTab: primaryRoute?.tabKey,

    activeSecondaryTab: activeSecondaryTab?.tabKey,
    secondaryNavigateTo,
  };
}
