import { toZonedTime, fromZonedTime, formatInTimeZone } from "date-fns-tz";
import { i18n } from "../../i18n";
import { enNZ } from "date-fns/locale/en-NZ";
import { parseISO } from "date-fns";
import {
  EventDateRangeOptions,
  FormatDateOptions,
  FormatType,
  ZonedDate,
} from "@flicket/utils";
import { TimezoneFragmentFragment } from "~graphql/sdk";

/**
 * Formats the given date based on the en-NZ locale and timezone into a human readable formatted date string.
 *
 * Uses format and toDate from 'date-fns-tz' internally
 *
 * @param date - ISO string representing UTC time
 * @param dateFormat - defaults to "dd/MM/yyyy"
 *
 * @returns A string representing the formatted date in the Organization timezone
 *
 * @remarks
 * This functions works based on a **fixed time zone**
 * This is done because events should always be displayed in the time zone where the events takes place
 */
export const formatDate = (date: string | Date, dateFormat = "dd/MM/yyyy") => {
  if (!date) return null;

  return formatInTimeZone(
    typeof date === "string" ? parseISO(date) : date,
    i18n.timezone,
    dateFormat,
    {
      locale: enNZ,
    }
  );
};

export const utcToLocal = (date: Date | string | number, tz: string) => {
  return toZonedTime(date, tz ?? i18n.timezone);
};

export const localToUtc = (date: Date | string | number, tz: string) => {
  return fromZonedTime(date, tz ?? i18n.timezone);
};

/**
 * Formats the date if given a string
 *
 * @param date - String representing the date
 *
 * @returns String with format dateArray[1]-dateArray[0]-dateArray[2] 😒
 */
export const formatDateFromString = (date: string) => {
  const dateArray = date.split("-");
  const formattedDate = [dateArray[1], dateArray[0], dateArray[2]].join("-");

  return formattedDate;
};

export type allowAdditionalProperties<T> = {
  [K in keyof T]: T[K] extends object ? allowAdditionalProperties<T[K]> : T[K];
} & { [key: string]: any };

export type EventLike = allowAdditionalProperties<{
  startDate?: string;
  endDate?: string;
  venue: TimezoneFragmentFragment;
}>;

export type VenueLike = allowAdditionalProperties<TimezoneFragmentFragment>;

export const formatEventDate = (
  date: Date | string,
  venue: VenueLike,
  type: FormatType = "datetime",
  options?: Partial<FormatDateOptions>
) => {
  return ZonedDate.format(date, type, {
    ...options,
    timeZone: venue?.timezone ?? i18n.timezone,
    locale: venue?.locale ?? i18n.locale,
  });
};

export const formatEventDateRange = (
  event: EventLike,
  type: EventDateRangeOptions["format"] = "basicShort",
  options?: Partial<FormatDateOptions>
) => {
  return ZonedDate.formatEventRange(event?.startDate, event?.endDate, {
    ...options,
    format: type,
    timeZone: event?.venue?.timezone ?? i18n.timezone,
    locale: event?.venue?.locale ?? i18n.locale,
  });
};

export function formatReportDate(
  date: Date | string,
  type: FormatType = "date",
  options?: Partial<FormatDateOptions>
) {
  return formatOrgDate(date, type, options);
}

export function formatOrgDate(
  date: Date | string,
  type: FormatType = "date",
  options?: Partial<FormatDateOptions>
) {
  return ZonedDate.format(date, type, {
    ...options,
    timeZone: i18n.timezone,
    locale: i18n.locale,
  });
}

export function formatReportDateRange(
  start: Date | string,
  end: Date | string,
  type: FormatType = "date",
  options?: Partial<FormatDateOptions>
) {
  return ZonedDate.formatRange(start, end, type, {
    ...options,
    timeZone: i18n.timezone,
    locale: i18n.locale,
  });
}
