import { useAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { SetStateAction, useContext, useState } from "react";
import { BaseContext } from "~context";
import { RichTextStyles } from "../richTextStyleTypes";

export const globalDefaultStyle: RichTextStyles = {
  backgroundColour: "#ffffff",
  bodyColour: "#ffffff",
  headingColour: "#181818",
  paragraphColour: "#181818",
  linkColour: "#3869d4",
  buttonColour: "#000000",
};

export const getOrgDefaultStyle = (primaryColour: string) => ({
  ...globalDefaultStyle,
  linkColour: primaryColour ?? "#000000",
  buttonColour: primaryColour ?? "#000000",
});

const stateAtom = atomWithReset(globalDefaultStyle);

export const useRichTextStyle = () => {
  const { organization } = useContext(BaseContext);
  const [hasAlteredStyle, setHasAlteredStyle] = useState(false);
  const [richTextStyle, setRichTextStyle] = useAtom(stateAtom);

  const primaryColour = organization?.branding?.primaryColor;

  const resetRichTextStyle = () => {
    setRichTextStyle(getOrgDefaultStyle(primaryColour));
    setHasAlteredStyle(false);
  };

  return {
    richTextStyle,
    setRichTextStyle: (value: SetStateAction<RichTextStyles>) => {
      setRichTextStyle(value);
      setHasAlteredStyle(true);
    },
    resetRichTextStyle,
    hasAlteredStyle,
  };
};
