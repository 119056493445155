import { Box, Input, Text } from "flicket-ui";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { Divider } from "~components";
import { EventMembershipDropDown } from "~components/common/RichText/EventMembershipDropDown";
import {
  getHeaderText,
  InsertActionButtons,
  InsertButtonOrLinkOnSuccessOptions,
  suggestedLinkTypes,
} from "~components/common/RichText/InsertModal";

export type SuggestedLinkType = typeof suggestedLinkTypes[number];

type ModalType = "link" | "button";

type InsertModalContentProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isEdit?: boolean;
  isEmail?: boolean;
  defaultValues?: {
    url?: string;
    content?: string;
    suggestedLink?: SuggestedLinkType;
    type?: ModalType;
    selectEvents?: boolean;
    eventId?: string;
    releaseId?: string;
  };
  onSuccess: ({
    url,
    content,
    suggestedLink,
    type,
    eventId,
    releaseId,
  }: InsertButtonOrLinkOnSuccessOptions) => void;
};

export const InsertSMSModalContent = ({
  setIsOpen,
  isEdit,
  defaultValues,
  onSuccess,
}: InsertModalContentProps) => {
  const [url, setUrl] = useState(defaultValues?.url ?? "");
  const [content, setContent] = useState(defaultValues?.content ?? "");
  const suggestedLink = defaultValues?.suggestedLink;

  const headerText = getHeaderText({
    type: "link",
    isEdit,
    suggestedLink,
  });

  return (
    <>
      <Box d="flex" alignItems="center">
        <Text fontWeight="heavy" fontSize={6}>
          {headerText}
        </Text>
      </Box>
      <Divider />

      {suggestedLink && (
        <EventMembershipDropDown
          onChange={({ url, name }) => {
            setContent(name);
            setUrl(url);
          }}
          selected={{ content, url }}
        />
      )}

      {!suggestedLink && (
        <Input
          mt={3}
          label={"URL"}
          value={url}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setUrl(e.target.value);
            setContent(e.target.value);
          }}
        />
      )}
      <Text mt={4} mb={4} fontSize={"14px" as any}>
        Link will be automatically shortened to 22 characters.
      </Text>

      <InsertActionButtons
        content={content}
        setIsOpen={setIsOpen}
        onSuccess={onSuccess}
        url={url}
        suggestedLink={suggestedLink}
        type={{ type: "link" }}
        eventId={null}
        releaseId={null}
        shouldLoadEvents={false}
      />
    </>
  );
};
