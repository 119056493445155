import { Box, Flex, Text } from "flicket-ui";
import { forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";
import { FolderTabs, Skeleton } from "~components";
import CustomModal from "~components/common/CustomModal";
import { useScreenSize } from "~hooks/useScreenSize";
import useLayoutContext from "../../hooks/useLayoutContext";
import { PRIMARY_NAVIGATION_KEYS } from "../../navigation/primary.config";
import EventSelect, { EventDisplay } from "./Select/EventSelect";
import MembershipSelect, { MembershipDisplay } from "./Select/MembershipSelect";
import { AnimatePresence, motion } from "framer-motion";
import SeasonSelect, { SeasonDisplay } from "./Select/SeasonSelect";

const DropdownButton = styled(Flex).attrs({ as: "button" })<{
  $isOpen: boolean;
  $isText: boolean;
}>`
  --focus-color: ${(p) => p.theme.colors.N800};

  border-radius: ${(p) => p.theme.radii.sm};
  background: ${(p) => (p.$isOpen ? `white` : `rgba(255, 255, 255, 0.5)`)};
  border: 1px solid ${(p) => p.theme.colors.N200};
  padding: ${(p) => (p.$isText ? 12 : p.theme.space[2])}px;
  justify-content: space-between;
  align-items: center;
  gap: ${(p) => p.theme.space[1]}px;
  width: 100%;

  &&:focus-visible {
    outline: 2px solid var(--focus-color);
    z-index: 3;
  }

  :hover {
    background: white;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.sm}) {
    padding: ${(p) => (p.$isText ? 12 : p.theme.space[3])}px;
  }
`;

const DropdownWrapper = styled(Flex)`
  flex-direction: column;
  position: absolute;
  background: ${(p) => p.theme.colors.white};
  box-shadow: ${(p) => p.theme.shadows.button};
  width: 100%;
  border-radius: ${(p) => p.theme.radii.sm};

  top: ${(p) => p.theme.space["1/4"]}px;
  z-index: 10;
`;

interface WrapperProps {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const MobileWrapper = forwardRef<HTMLElement, WrapperProps>(
  (props, ref) => {
    const { children, isOpen, setIsOpen } = props;
    return (
      <CustomModal isOpen={isOpen} close={() => setIsOpen(false)} ref={ref}>
        <CustomModal.Header>Select event</CustomModal.Header>
        <CustomModal.Content>{children}</CustomModal.Content>
      </CustomModal>
    );
  }
);

MobileWrapper.displayName = "MobileWrapper";

export const DesktopWrapper = forwardRef<HTMLElement, WrapperProps>(
  (props, ref) => {
    const { children, isOpen } = props;

    return (
      <AnimatePresence>
        {isOpen && (
          <DropdownWrapper ref={ref} position="absolute" background="white">
            <motion.div
              initial={{
                height: 0,
                opacity: 0,
                overflow: "hidden",
              }}
              animate={{
                height: "auto",
                opacity: 1,
                transitionEnd: {
                  overflow: "visible",
                },
              }}
              transition={{ duration: 0.2 }}
              exit={{
                height: 0,
                opacity: 0,
                overflow: "hidden",
                transition: {
                  duration: 0.15,
                },
              }}
            >
              {/* 
              height auto can't be used with padding, it will cause the animation jump in the end 
              so we need to move padding to a child element
              check `height: "auto" is jumping` section in this link 
              https://github.com/framer/motion/blob/main/.github/ISSUE_TEMPLATE/bug_report.md
              */}
              <Box padding={2}>{children}</Box>
            </motion.div>
          </DropdownWrapper>
        )}
      </AnimatePresence>
    );
  }
);

DesktopWrapper.displayName = "DesktopWrapper";

interface DropdownContentProps {
  setIsOpen: (isOpen: boolean) => void;
}

interface SourceSwitcherDropdownProps {
  buttonContent: React.ReactNode;
  dropdownContent: (props: DropdownContentProps) => React.ReactNode;
  isTextButton?: boolean;
}

export const SourceSwitcherDropdown = (props: SourceSwitcherDropdownProps) => {
  const { buttonContent, dropdownContent, isTextButton } = props;
  const dropdownRef = useRef<HTMLElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useOnClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  const screenSizes = useScreenSize();
  const isMobile = screenSizes.isTabletPortraitDown;
  const Wrapper = isMobile ? MobileWrapper : DesktopWrapper;

  return (
    <Box
      position="relative"
      zIndex={100}
      ref={!isMobile ? dropdownRef : undefined}
    >
      <DropdownButton
        onClick={() => setIsOpen(!isOpen)}
        $isOpen={isOpen}
        $isText={isTextButton}
      >
        {buttonContent}
      </DropdownButton>

      <Box
        position={isTextButton ? "fixed" : "absolute"}
        width={
          isTextButton
            ? `${dropdownRef?.current?.offsetWidth ?? 100}px`
            : "100%"
        }
      >
        <Wrapper
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          ref={isMobile ? dropdownRef : undefined}
        >
          {dropdownContent({ setIsOpen })}
        </Wrapper>
      </Box>
    </Box>
  );
};

export default function SourceSwitcher() {
  const context = useLayoutContext();

  const primaryNavTabs = [
    {
      key: PRIMARY_NAVIGATION_KEYS.EVENTS,
      name: "Events",
      content: ({ setIsOpen }: DropdownContentProps) => (
        <EventSelect onChange={() => setIsOpen(false)} />
      ),
    },
    ...(context.searchableMemberships.length > 0
      ? [
          {
            key: PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS,
            name: "Memberships",
            content: ({ setIsOpen }: DropdownContentProps) => (
              <MembershipSelect onChange={() => setIsOpen(false)} />
            ),
          },
        ]
      : []),
    ...(context.searchableSeasons.length > 0
      ? [
          {
            key: PRIMARY_NAVIGATION_KEYS.SEASONS,
            name: "Seasons",
            content: ({ setIsOpen }: DropdownContentProps) => (
              <SeasonSelect onChange={() => setIsOpen(false)} />
            ),
          },
        ]
      : []),
  ];

  const initialActiveIndex = primaryNavTabs.findIndex(
    (item) => item.key === context.type
  );

  const [activeIndex, setActiveIndex] = useState(initialActiveIndex);

  useEffect(() => {
    if (!context.searchIndexLoading) {
      setActiveIndex(
        primaryNavTabs.findIndex((item) => item.key === context.type)
      );
    }
  }, [context.searchIndexLoading]);

  let ButtonContent: JSX.Element | null = null;

  if (context.type === PRIMARY_NAVIGATION_KEYS.EVENTS) {
    ButtonContent = <EventDisplay />;
  }

  if (context.type === PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS) {
    ButtonContent = <MembershipDisplay />;
  }

  if (context.type === PRIMARY_NAVIGATION_KEYS.SEASONS) {
    ButtonContent = <SeasonDisplay />;
  }

  return (
    <SourceSwitcherDropdown
      isTextButton={false}
      buttonContent={ButtonContent}
      dropdownContent={(props) => {
        if (context.searchIndexLoading) {
          return (
            <Box>
              <Text>Loading...</Text>
              <Skeleton />
            </Box>
          );
        }

        return (
          <FolderTabs
            activeIndex={activeIndex >= 0 ? activeIndex : 0}
            onTabChange={setActiveIndex}
            items={primaryNavTabs.map((item) => ({
              ...item,
              content: item.content(props),
            }))}
            tabContentWrapperProps={{
              padding: 0,
              borderRadius: "none",
              background: "none",
            }}
          />
        );
      }}
    />
  );
}
