import { Box, PrimaryButton, Stack, TertiaryButton, Text } from "flicket-ui";
import Modal from "~components/common/CustomModal/CustomModal";
import { FormEvent, useRef, useState } from "react";
import { Input } from "~components/common/Input";
import { VideoElement } from "../video";
import ReactPlayer from "react-player/lazy";

export type FormValues = Pick<VideoElement, "url">;
export interface InsertVideoFormProps {
  close: () => void;
  onSubmit: (values: FormValues) => Promise<void>;
  afterSubmit?: () => void;
  video: VideoElement;
}

export default function InsertVideoForm(props: InsertVideoFormProps) {
  const { close, onSubmit } = props;

  const textRef = useRef<HTMLInputElement>(null);
  const [validationError, setValidationError] = useState<string>();

  function _onSubmit(e: FormEvent) {
    e.preventDefault();

    const inputValue = textRef.current.value ?? "";

    if (!ReactPlayer.canPlay(inputValue)) {
      setValidationError("Unknown video link");
      return;
    }

    void onSubmit({
      url: inputValue,
    });
  }

  return (
    <>
      <Modal.Header>Insert video</Modal.Header>
      <Modal.Content>
        <Stack gap={3} direction="vertical">
          <Box>
            <Input
              ref={textRef}
              autoFocus
              placeholder="https://"
              label={<Text variant="formLabel">Video URL</Text>}
              error={validationError}
            />
            <Text variant="small" mt={1}>
              Paste a video link from YouTube or Vimeo.
            </Text>
          </Box>
        </Stack>
      </Modal.Content>
      <Modal.Footer>
        <Stack gap={1}>
          <TertiaryButton onClick={close}>Cancel</TertiaryButton>
          <PrimaryButton type="submit" onClick={_onSubmit}>
            Save
          </PrimaryButton>
        </Stack>
      </Modal.Footer>
    </>
  );
}

// function extractSrcAndId(htmlString: string): { src: string; id: string } {
//   const srcRegex = /src="([^"]*)"/;
//   const match = htmlString.match(srcRegex);

//   if (!match) {
//     return { src: null, id: null };
//   }

//   const src = match[1];
//   const lastSlashIndex = src.lastIndexOf("/");
//   const id = lastSlashIndex !== -1 ? src.slice(lastSlashIndex + 1) : null;

//   return { src, id };
// }
