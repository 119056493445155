import {
  earliestStartDate,
  greatestEndDate,
} from "~lib/helpers/dates/formatDateRange";
import { FormValues } from "../schema.types";
import { Event } from "~graphql/sdk";

export function validateEventDateRanges(dates: FormValues["dates"]) {
  return dates.reduce(
    (acc, range, index) => {
      if (new Date(range.endDate) <= new Date(range.startDate)) {
        acc.push({
          name: `dates.${index}.endDate`,
          type: "dateRange",
          message: "End time must be after the start time",
        });
      }
      return acc;
    },
    [] as {
      name: string;
      type: "dateRange";
      message: string;
    }[]
  );
}

export function validateTicketSaleDates(
  ticketDates: FormValues["listedReleaseOptions"],
  eventDates: FormValues["dates"] = []
) {
  const errors: {
    name: string;
    type: "dateRange";
    message: string;
  }[] = [];

  if (ticketDates.startConditionDate && ticketDates.endConditionDate) {
    if (
      new Date(ticketDates.startConditionDate) >=
      new Date(ticketDates.endConditionDate)
    ) {
      errors.push({
        name: "listedReleaseOptions.endConditionDate",
        type: "dateRange",
        message: "Ticket sales must end after ticket sales begin",
      });
    }
  }

  if (
    eventDates[eventDates.length - 1]?.endDate &&
    ticketDates.endConditionDate
  ) {
    if (
      new Date(ticketDates.endConditionDate) >
      new Date(greatestEndDate(eventDates as Event["dates"]))
    ) {
      errors.push({
        name: "listedReleaseOptions.endConditionDate",
        type: "dateRange",
        message: "Ticket sales must end before event is finished",
      });
    }
  }

  if (eventDates[0]?.startDate && ticketDates.startConditionDate) {
    if (
      new Date(ticketDates.startConditionDate) >
      new Date(earliestStartDate(eventDates as Event["dates"]))
    ) {
      errors.push({
        name: "listedReleaseOptions.startConditionDate",
        type: "dateRange",
        message: "Ticket sales cannot start after event has begun",
      });
    }
  }

  return errors;
}
