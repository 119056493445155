import { Text } from "flicket-ui";
import { useAtom } from "jotai";
import { CSSProperties } from "react";
import { Editor, Element, Range, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import {
  ReactEditor,
  RenderElementProps,
  useEditor,
  useFocused,
  useSelected,
} from "slate-react";
import { useTheme } from "styled-components";
import { ExtendedFile } from "~graphql/sdk";
import { useSDK } from "~hooks";
import { getError, getGCSFile, showToast } from "~lib";
import { delayedPromise } from "~lib/helpers/handlePromise";
import { Icon } from "../Icon";
import { imageModalAtom } from "./InsertImageModal";
import { Button } from "./components";
import isEqual from "lodash/isEqual";
import { topOfDocumentSelection } from "./util";
import { PencilSimple, Trash } from "@phosphor-icons/react";

export interface ImageElement extends Element {
  type: "image";
  size: "full" | "large" | "medium" | "small";
  url?: string;
  href?: string;
}

export const insertImage = (
  editor: ReactEditor,
  image: ImageElement,
  isBanner: boolean
) => {
  let blurSelection = (editor.blurSelection as any) as Range;

  if (!blurSelection || isBanner) {
    blurSelection = topOfDocumentSelection;
  }

  Transforms.insertNodes(editor, image, {
    at: blurSelection,
  });

  // add 1 to focus on the image
  const newRange: Range = {
    anchor: {
      offset: 0,
      path: [blurSelection?.anchor.path[0] + 1, 0],
    },
    focus: {
      offset: 0,
      path: [blurSelection?.focus.path[0] + 1, 0],
    },
  };

  // If this is at the top of the document
  if (isEqual(blurSelection, topOfDocumentSelection)) {
    const node = editor.children[blurSelection.anchor.path[0]];
    if (node.type === "paragraph" && node.children[0].text === "") {
      Transforms.removeNodes(editor, {
        at: blurSelection,
      });
      Transforms.insertNodes(
        editor,
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
        {
          at: blurSelection,
        }
      );
    }
    editor.selection = blurSelection;
  } else {
    editor.selection = newRange;
  }
  // add a paragraph so the user can edit access the next line in the editor
  Transforms.insertNodes(editor, [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);
  ReactEditor.focus(editor);
};

interface EditImageButtonProps {
  image: ImageElement | null;
}

export const EditImageButton = (props: EditImageButtonProps) => {
  const { image } = props;
  const [_, setImageModalState] = useAtom(imageModalAtom);

  if (!image) {
    return null;
  }

  return (
    <>
      <Button onClick={() => setImageModalState({ isOpen: true, image })}>
        <Icon icon={<PencilSimple />} mr={"1/2"} />
        <Text variant="regular">Edit image</Text>
      </Button>
    </>
  );
};

export const DeleteImageButton = () => {
  const editor = useEditor();

  return (
    <Button
      onClick={() => {
        editor.deleteBackward("block");
      }}
    >
      <Icon icon={<Trash />} mr={"1/2"} />
      <Text variant="regular">Delete image</Text>
    </Button>
  );
};

export const uploadImage = async (
  file: File,
  sdk: ReturnType<typeof useSDK>
): Promise<string> => {
  const newFile = Object.assign(file, {
    preview: URL.createObjectURL(file),
  });

  const [error, data] = await delayedPromise(async () =>
    sdk.uploadImage({
      file: {
        file: newFile,
      },
    })
  );

  if (error) {
    showToast(getError(error, "graphQL"), "error");
    return Promise.reject(error);
  }

  return getGCSFile(data.uploadImage as ExtendedFile);
};

export const fileSelectHandler = async (
  file: File,
  editor: ReactEditor,
  sdk: ReturnType<typeof useSDK>,
  isBanner: boolean
): Promise<void | Error> => {
  const url = await uploadImage(file, sdk);

  const image: ImageElement = {
    type: "image",
    url,
    size: isBanner ? "full" : "large",
    children: [],
  };

  insertImage(editor, image, isBanner);
};

export const withImages = (editor: Editor & ReactEditor & HistoryEditor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  return editor;
};

export const ImageComponent = ({
  attributes,
  children,
  element,
  style,
}: RenderElementProps & { style?: CSSProperties }) => {
  const selected = useSelected();
  const focused = useFocused();
  const theme = useTheme();

  const editor = useEditor();

  // This will be the path to the image element.
  const path = ReactEditor.findPath(editor, element);

  const isBannerImage = element.size === "full" && path[0] === 0;

  let imageStyle: CSSProperties = {
    cursor: "pointer",
  };

  if (element.size) {
    imageStyle = {
      ...imageStyle,
      width:
        element.size === "small"
          ? "33%"
          : element.size === "medium"
          ? "50%"
          : element.size === "large"
          ? "100%"
          : "calc(100% + 48px)",
      ...(element.size === "full" && {
        marginLeft: "-24px",
      }),
      ...(isBannerImage && {
        marginTop: "-32px",
      }),
      ...style,
    };
  }

  return (
    <div {...attributes} style={imageStyle}>
      {/* children must be passed even not used https://github.com/ianstormtaylor/slate/issues/3930 */}
      {children}
      <div contentEditable={false}>
        <img
          style={{
            minWidth: "100%",
            borderRadius: element.size === "full" ? 0 : "4px",
            boxShadow:
              selected && focused ? `0 0 0 2px ${theme.colors.P300}` : "none",
            marginBottom: isBannerImage ? "45px" : "0",
          }}
          src={element.url as string}
          alt="/static/no-image.jpg"
        />
      </div>
    </div>
  );
};
