import { addDays, subDays } from "date-fns";
import { useEffect, useState } from "react";
import {
  EventsListUseParamsQuery,
  MembershipsListUseParamsQuery,
  OrderChannel,
  PointReportingFilterSource,
  ReleaseType,
  ReportingFilterSource,
  ReportingFinancialSalesBreakdownSource,
  Role,
  DefaultDateRange,
} from "~graphql/sdk";
import {
  EventsListUseParamsDocument,
  MembershipsListUseParamsDocument,
  ReleasesListUseParamasDocument,
} from "~graphql/typed-document-nodes";
import { useUser } from "~hooks";
import { formatDate } from "~lib/helpers";
import { useQuery } from "../../../hooks/useQuery";

const CHANNEL_OPTIONS = [
  { label: "All channels", value: undefined },
  { label: "Online", value: OrderChannel.Online },
  { label: "Back office", value: OrderChannel.BackOffice },
  { label: "POS", value: OrderChannel.Pos },
];

export interface DateParams {
  dates?: Date[];
  startDate: Date;
  endDate: Date;
}

export interface ParamsProps extends DateParams {
  channel?: OrderChannel;
  source:
    | ReportingFilterSource
    | ReportingFinancialSalesBreakdownSource
    | PointReportingFilterSource;
  sourceId?: string;
  releaseId?: string;
  seasonId?: string;
  status?: string[];
  orderBy?: any;
  gateway?: string;
  seller?: string;
  excludeAllReleases?: boolean;
  release?: string;
  ready?: boolean;
  tagId?: string[];
}

export type ExcludeProps<T extends string> = {
  [key in T]?: ParamsExcludeProps;
};

type ParamsExcludeProps = {
  [key in keyof Partial<ParamsProps>]: boolean;
} & { point?: boolean };

export const useFilterParams = (args?: {
  initialParams?: Partial<ParamsProps>;
  exclude?: ParamsExcludeProps;
  excludeAllEvents?: boolean;
  excludeAllMemberships?: boolean;
  excludeAllReleases?: boolean;
  excludeRestrictedEvents?: boolean;
  // add point to source
  enablePointSource?: boolean;
  isSalesOutlet?: boolean;
}) => {
  const pointSourceOptions = Object.keys(PointReportingFilterSource).map(
    (key) => ({
      label: key,
      value: PointReportingFilterSource[key] as PointReportingFilterSource,
    })
  );

  const sorceOptions = Object.keys(ReportingFilterSource).map((key) => ({
    label: key,
    value: ReportingFilterSource[key] as ReportingFilterSource,
  }));

  const SOURCE_OPTIONS =
    args?.enablePointSource && !args?.exclude?.point
      ? pointSourceOptions
      : sorceOptions;

  const { user } = useUser();
  const {
    exclude,
    initialParams,
    excludeAllEvents,
    excludeAllMemberships,
    excludeAllReleases,
    excludeRestrictedEvents,
  } = args || {};

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [params, setParams] = useState<ParamsProps>({
    channel: null,
    ...(!exclude?.source && { source: ReportingFilterSource.Overall }),
    ...(!exclude?.dates && {
      dates: [subDays(today, 31), addDays(today, 1)],
      startDate: subDays(today, 31),
      endDate: addDays(today, 1),
    }),
    ready: false,
    ...initialParams,
  });

  const { data: eventsData, error: errorEvents } = useQuery(
    EventsListUseParamsDocument,
    {
      where: {
        isOutlet: args?.isSalesOutlet,
        isActive: null,
      },
    }
  );

  const eventsAccessFilter = (
    events: EventsListUseParamsQuery["events"]["edges"]
  ): EventsListUseParamsQuery["events"]["edges"] => {
    if (
      !user?.roles.includes(Role.EventReporting) ||
      !excludeRestrictedEvents
    ) {
      return events;
    }

    return events?.filter((event) =>
      user?.accessEventIds?.includes(event.node.id)
    );
  };

  const events = eventsAccessFilter(eventsData?.events.edges)
    ?.map((event) => event?.node)
    .sort((a, b) => {
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    });

  const eventOptions = [
    !excludeAllEvents && {
      label: "All events",
      value: undefined,
    },
    ...(events?.map((event) => ({
      label: `${formatDate(new Date(event.startDate), "dd-MM-yyyy")} | ${
        event?.title
      }`,
      value: event?.id,
    })) || []),
  ]?.filter((e) => !!e);

  const { data: releaseData, error: errorReleases } = useQuery(
    params.sourceId && ReleasesListUseParamasDocument,
    {
      event: params.sourceId,
    }
  );

  let releases = releaseData?.releases;

  if (args?.isSalesOutlet) {
    releases = releases?.filter(
      (release) => release.type === ReleaseType.Outlet
    );
  }

  const membershipAccessFilter = (
    memberships: MembershipsListUseParamsQuery["memberships"]["edges"]
  ): MembershipsListUseParamsQuery["memberships"]["edges"] => {
    if (
      !user?.roles.includes(Role.EventReporting) ||
      !excludeRestrictedEvents
    ) {
      return memberships;
    }

    return memberships?.filter((membership) =>
      user?.accessMembershipIds?.includes(membership?.node?.id)
    );
  };

  const { data: membershipsData, error: errorMemberships } = useQuery(
    !exclude?.source &&
      !excludeAllMemberships &&
      MembershipsListUseParamsDocument,
    {
      where: { isActive: null },
    }
  );
  const memberships = membershipAccessFilter(membershipsData?.memberships.edges)
    ?.map(({ node }) => node)
    .sort((a, b) => {
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    });

  let defaultDateRange: DefaultDateRange;
  if (
    params.source === ReportingFilterSource.Event &&
    params.sourceId &&
    events?.length > 0
  ) {
    defaultDateRange = events.find((event) => event.id === params.sourceId)
      ?.defaultDateRange;
  }

  if (
    params.source === ReportingFilterSource.Membership &&
    params.sourceId &&
    memberships?.length > 0
  ) {
    defaultDateRange = memberships.find(
      (membership) => membership.id === params.sourceId
    )?.defaultDateRange;
  }

  const releaseOptions = [
    !excludeAllReleases && {
      label: "All releases",
      value: "",
    },
    ...(releases?.map((release) => ({
      label: release?.name,
      value: release?.id,
    })) || []),
  ]?.filter((r) => !!r);

  const membershipOptions = [
    !excludeAllMemberships && {
      label: "All memberships",
      value: undefined,
    },
    ...(memberships?.map((membership) => ({
      label: membership?.name,
      value: membership?.id,
    })) || []),
  ]?.filter((m) => !!m);

  useEffect(() => {
    if (!defaultDateRange) {
      setParams({
        ...params,
        dates: [subDays(today, 31), addDays(today, 1)],
        startDate: subDays(today, 31),
        endDate: addDays(today, 1),
      });
      return;
    }

    if (defaultDateRange?.startDate && defaultDateRange?.endDate) {
      setParams({
        ...params,
        dates: [
          new Date(defaultDateRange?.startDate),
          new Date(defaultDateRange?.endDate),
        ],
        startDate: new Date(defaultDateRange?.startDate),
        endDate: new Date(defaultDateRange?.endDate),
      });
    } else {
      setParams({
        ...params,
        dates: [subDays(today, 31), addDays(today, 1)],
        startDate: subDays(today, 31),
        endDate: addDays(today, 1),
      });
    }
  }, [params.sourceId]);

  const readyForDisplay = (params: ParamsProps): boolean => {
    if (
      params.source === ReportingFilterSource.Overall ||
      params.source === PointReportingFilterSource.Package
    ) {
      return true;
    } else if (params.source === ReportingFilterSource.Event) {
      if (
        (params.sourceId && releases && events) ||
        (!params.sourceId && events)
      ) {
        return true;
      }
      return false;
    } else if (params.source === ReportingFilterSource.Membership) {
      if (memberships) {
        return true;
      }
      return false;
    }
  };

  const ready = readyForDisplay(params);

  useEffect(() => {
    if (ready) {
      setParams({ ...params, ready });
    }
  }, [ready]);

  const hasError: boolean =
    !!errorEvents || !!errorReleases || !!errorMemberships;

  const timeHasLoaded = (): boolean => {
    if (
      (params.source === ReportingFilterSource.Event ||
        params.source === ReportingFilterSource.Membership) &&
      params.sourceId
    ) {
      return !!defaultDateRange;
    }
    return true;
  };

  return {
    eventOptions,
    membershipOptions,
    releaseOptions,
    events,
    errorEvents,
    releases,
    errorReleases,
    memberships,
    errorMemberships,
    params,
    setParams,
    channelOptions: CHANNEL_OPTIONS,
    sourceOptions: SOURCE_OPTIONS,
    exclude,
    ready,
    hasError,
    timeHasLoaded,
  };
};

export type ParamsMethods = ReturnType<typeof useFilterParams>;
