import { useFormContext, Controller } from "react-hook-form";
import {
  Box,
  Flex,
  Spinner,
  Stack,
  SystemProps,
  TertiaryButton,
  Text,
  UnderlineButton,
} from "flicket-ui";
import {
  CSSProperties,
  ChangeEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { getImage, showErrorToast } from "~lib/helpers";
import { ExtendedFile, ImageGalleryItem } from "~graphql/sdk";
import { Icon, IconLinkButton } from "~components";
import ImageCropperModal from "~components/common/ImageCropper/ImageCropperModal";
import styled, { css } from "styled-components";
import { Image, Images, Plus, X } from "@phosphor-icons/react";
import { useSDK } from "~hooks";
import NextImage from "next/future/image";
import handlePromise from "~lib/helpers/handlePromise";
import { getGCSFile } from "src/lib/helpers/getGCPImage";
import { v4 as uuidV4 } from "uuid";
import { on } from "events";

export const ImgPreview = styled.img`
  width: 100%;
  border-radius: ${(p) => p.theme.radii.md};
`;

type THandleImageSelect = (callback?: (blob: Blob) => void) => void;

interface ChildrenProps {
  hasImage: boolean;
  handleImageSelect: THandleImageSelect;
  removeImage: () => void;
  previewImageUrl: string;
}

interface ImageUploadProps {
  name: string;
  onChange: (file: File) => void;
  children: (props: ChildrenProps) => ReactNode;
  value: ExtendedFile;
  aspectRatio?: number;
}

const ALLOWED_IMAGE_TYPES = [
  "jpeg",
  "png",
  "gif",
  "wepb",
  "svg",
  "avif",
] as const;

export const imageTypesShownToUser = ALLOWED_IMAGE_TYPES.filter(
  (type) => type !== "avif"
).map((type) => type.toUpperCase());

let callback: (blob: Blob) => void;

export function ImageUpload({
  onChange,
  name,
  value,
  children,
}: ImageUploadProps) {
  const [imageUrl, setImageUrl] = useState<string>();
  const inputRef = useRef<HTMLInputElement>();

  const removePreview = () => {
    setImageUrl(undefined);
  };

  const removeImage = () => {
    removePreview();
    onChange(null);
  };

  const handleImageSelect: THandleImageSelect = (afterSelect) => {
    callback = afterSelect;
    // Open file input box on click of another element
    inputRef?.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files[0];

    if (!fileObj) {
      callback = undefined;
      return;
    }

    // Reset file input
    event.target.value = null;

    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result as string);
    };

    if (fileObj) {
      // convert image file to base64 string
      reader.readAsDataURL(fileObj);

      onChange(fileObj);
      callback?.(fileObj);
    }
  };

  const uploadedImageUrl = getImage(value, "");
  const previewImageUrl = imageUrl || uploadedImageUrl;
  const hasImage = !!previewImageUrl;

  return (
    <>
      {children({
        hasImage,
        previewImageUrl,
        removeImage,
        handleImageSelect,
      })}

      <input
        name={name}
        style={{ display: "none" }}
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        accept={ALLOWED_IMAGE_TYPES.map((type) => `image/${type}`).join(",")}
      />
    </>
  );
}

async function isAnimatedImageFormat(file: File): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const result = fileReader.result as ArrayBuffer;
      const uint8Array = new Uint8Array(result);

      // GIF signature: GIF87a or GIF89a (starts with "GIF")
      if (
        uint8Array[0] === 0x47 &&
        uint8Array[1] === 0x49 &&
        uint8Array[2] === 0x46
      ) {
        // Look for multiple Graphics Control Extension blocks (0x00 0x21 0xF9 0x04).
        // Which will tell as if the image is animated or not.
        let frames = 0;
        for (let i = 0; i < uint8Array.length - 9; i++) {
          if (
            uint8Array[i] === 0x00 &&
            uint8Array[i + 1] === 0x21 &&
            uint8Array[i + 2] === 0xf9 &&
            uint8Array[i + 3] === 0x04
          ) {
            frames++;
            // We got more than one frame, it's an animated GIF
            if (frames > 1) return resolve(true);
          }
        }
        return resolve(false);
      }

      // Not a GIF/animated image format
      return resolve(false);
    };

    fileReader.onerror = (error) => reject(error);

    fileReader.readAsArrayBuffer(file);
  });
}

// This one is designed to be a drop in replacement for ImageUpload but instead it
// intercepts the onChange event and opens a modal to allow the user to crop the image
function ImageUploadWithCrop(props: ImageUploadProps) {
  const { children, onChange, aspectRatio, ...rest } = props;

  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [croppedPreviewImageUrl, doNotCallDirectly] = useState<string | null>();
  const [filename, setFilename] = useState<string | undefined>();

  // Revoke the object URL, to allow the garbage collector to destroy the uploaded file.
  const cleanUpCroppedImage = (croppedPreviewImageUrl?: string) => {
    if (croppedPreviewImageUrl) {
      URL.revokeObjectURL(croppedPreviewImageUrl);
    }
  };

  const setCroppedPreviewImageUrl = (url: string) => {
    cleanUpCroppedImage(croppedPreviewImageUrl);
    doNotCallDirectly(url);
  };

  useEffect(() => {
    return () => {
      cleanUpCroppedImage(croppedPreviewImageUrl);
    };
  }, [croppedPreviewImageUrl]);

  return (
    <>
      <ImageUpload
        {...rest}
        onChange={(file) => {
          const handleFileChange = async () => {
            if (!file) {
              onChange(null);
              setFilename(undefined);
              setCroppedPreviewImageUrl(null);
            } else {
              setFilename(file.name);
              const isAnimated = await isAnimatedImageFormat(file);
              if (isAnimated) {
                setCroppedPreviewImageUrl(URL.createObjectURL(file));
                onChange(file);
              } else {
                setCropModalOpen(true);
              }
            }
          };

          void handleFileChange();
        }}
      >
        {(renderProps) => {
          const { removeImage, previewImageUrl } = renderProps;

          const newPreviewImageUrl =
            croppedPreviewImageUrl || (!cropModalOpen && previewImageUrl);

          return (
            <>
              {children({
                ...renderProps,
                hasImage: !!newPreviewImageUrl,
                previewImageUrl: newPreviewImageUrl,
              })}
              <ImageCropperModal
                src={previewImageUrl}
                isOpen={cropModalOpen}
                onClose={() => {
                  removeImage();
                  setCropModalOpen(false);
                }}
                onCrop={(blob) => {
                  setCroppedPreviewImageUrl(URL.createObjectURL(blob));

                  const file = new File(
                    [blob],
                    filename ?? "cropped-image.png"
                  );
                  onChange(file);
                  setCropModalOpen(false);
                }}
                stencilProps={{
                  aspectRatio,
                }}
              />
            </>
          );
        }}
      </ImageUpload>
    </>
  );
}

export function ImageUploadField({
  name,
  title,
  description,
  previewProps,
}: {
  name: string;
  title?: string;
  description?: string;
  previewProps?: SystemProps;
}) {
  const { control } = useFormContext();
  return (
    <Controller
      defaultValue={null}
      control={control}
      name={name}
      render={(renderProps) => (
        <ImageUpload {...renderProps}>
          {({ handleImageSelect, removeImage, hasImage, previewImageUrl }) => (
            <>
              <Flex
                mb={2}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                {title && (
                  <Flex flexDir={"column"} pr={2}>
                    <Text variant="regular">{title}</Text>
                    {description && <Text variant="small">{description}</Text>}
                  </Flex>
                )}

                <Text
                  variant="regular"
                  textDecoration={"underline"}
                  cursor={"pointer"}
                  color="P300"
                  onClick={() => {
                    hasImage ? removeImage() : handleImageSelect();
                  }}
                >
                  {hasImage ? "Remove" : "Upload"}
                </Text>
              </Flex>

              {hasImage && (
                <Box mb={4} {...previewProps}>
                  <ImgPreview src={previewImageUrl} title={title} />
                </Box>
              )}
            </>
          )}
        </ImageUpload>
      )}
    />
  );
}

const StyledFlex = styled(Flex)`
  transition: background-color 0.06s ease;
  &:hover {
    background-color: ${(p) => p.theme.colors.N200};
  }
`;

export function ImageUploadEmptyState(
  props: {
    aspectRatio: number;
    handleImageSelect: THandleImageSelect;
    description?: ReactNode;
    ctaText?: ReactNode;
  } & SystemProps
) {
  const {
    aspectRatio,
    handleImageSelect,
    description,
    ctaText,
    ...rest
  } = props;
  return (
    <StyledFlex
      css={`
        aspect-ratio: ${aspectRatio};
      `}
      backgroundColor={"N100"}
      flexDir={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      onClick={() => handleImageSelect()}
      cursor={"pointer"}
      borderRadius="md"
      // distorts the aspect ratio on smaller screens but I think
      // this is okay as the inner content will also not fit.
      p={3}
      {...rest}
    >
      <Icon icon={<Image size={80} weight="thin" />} mb={2} color="N500" />
      {description && (
        <Text
          maxWidth={330}
          mb={2}
          textAlign={"center"}
          variant="regular"
          color="N600"
        >
          {description}
        </Text>
      )}
      <TertiaryButton
        backgroundColor={"white"}
        color="N800"
        pointerEvents="none"
      >
        {ctaText}
      </TertiaryButton>
    </StyledFlex>
  );
}

export function ImageUploadFieldBanner({
  name,
  description,
  cropImage = false,
  ctaText = "Upload banner image",
  defaultValue = undefined,
  aspectRatio = 2 / 1,
}: {
  name: string;
  description: ReactNode;
  aspectRatio?: number;
  ctaText?: ReactNode;
  cropImage?: boolean;
  defaultValue?: unknown;
}) {
  const { control } = useFormContext();

  const ImageUploadComponent = cropImage ? ImageUploadWithCrop : ImageUpload;

  return (
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={name}
      render={(props) => (
        <ImageUploadComponent {...props} aspectRatio={aspectRatio}>
          {({ handleImageSelect, removeImage, hasImage, previewImageUrl }) => (
            <>
              {!hasImage && (
                <ImageUploadEmptyState
                  aspectRatio={aspectRatio}
                  handleImageSelect={handleImageSelect}
                  description={description}
                  ctaText={ctaText}
                />
              )}

              {hasImage && (
                <Box mb={4} position={"relative"}>
                  <Box
                    css={css({
                      position: "absolute",
                      top: -50,
                      right: 0,
                    })}
                  >
                    <UnderlineButton
                      onClick={removeImage}
                      fontSize={3}
                      color={"P300"}
                      fontWeight={"regular"}
                      textDecoration={"underline"}
                    >
                      Remove
                    </UnderlineButton>
                  </Box>
                  <ImgPreview src={previewImageUrl} title={"Event banner"} />
                </Box>
              )}
            </>
          )}
        </ImageUploadComponent>
      )}
    />
  );
}

export function ImageUploadWithText({
  name,
  title,
  subtitle,
}: {
  name: string;
  title: string;
  subtitle?: ReactNode;
}) {
  const { control } = useFormContext();
  return (
    <Controller
      defaultValue={null}
      control={control}
      name={name}
      render={(props) => {
        return (
          <ImageUpload {...props}>
            {({
              handleImageSelect,
              removeImage,
              hasImage,
              previewImageUrl,
            }) => (
              <>
                {/* Replace this out with an underline button sometime */}
                <AddButton
                  onClick={hasImage ? removeImage : handleImageSelect}
                  title={hasImage ? "Remove image" : title}
                />

                {subtitle}

                {hasImage && (
                  <Box mb={4} mt={1}>
                    <ImgPreview src={previewImageUrl} title={title} />
                  </Box>
                )}
              </>
            )}
          </ImageUpload>
        );
      }}
    />
  );
}

function AddButton({
  title,
  onClick,
}: {
  title: string;
  onClick?: () => void;
}) {
  return (
    <Flex justifyContent="flex-start" alignItems={"center"}>
      <Icon
        as={Flex}
        icon={<Plus weight="regular" size={15} />}
        color="P300"
        pt={"1px" as any}
        mr={"1/2"}
      />
      <Text
        as={Flex}
        textDecoration={"underline"}
        cursor={"pointer"}
        color="P300"
        onClick={onClick}
        fontSize={3}
      >
        {title}
      </Text>
    </Flex>
  );
}

export function ImageGallery({
  name,
  title,
  description,
  aspectRatio = "3 / 2",
  onRemoveImage,
  emptyContentComponent,
}: {
  name: string;
  title: string;
  description?: string;
  aspectRatio?: CSSProperties["aspectRatio"];
  onRemoveImage?: (
    newItems: ImageGalleryItem[],
    removedItem: ImageGalleryItem
  ) => void;
  emptyContentComponent?: (onClick: THandleImageSelect) => ReactNode;
}) {
  const { control } = useFormContext();

  const sdk = useSDK();
  const [uploading, setUploading] = useState<string[]>([]);
  const [loaded, setLoaded] = useState<string[]>([]);

  return (
    <Controller
      defaultValue={null}
      control={control}
      name={name}
      render={(renderProps) => {
        const handleImageChange = async (fileObj: File) => {
          const id = uuidV4() as string;

          setUploading(uploading.concat(id));

          const value = (renderProps.value ?? []).concat({
            id,
          });

          renderProps.onChange(value);

          const [error, data] = await handlePromise(async () =>
            sdk.uploadImage({
              file: {
                file: fileObj,
              },
            })
          );

          if (error) {
            showErrorToast("Failed to upload image");
            return renderProps.onChange(value.filter((item) => item.id !== id));
          }

          renderProps.onChange(
            value.map((item) => {
              if (item.id === id) {
                return {
                  id,
                  title: "",
                  original: {
                    src: getGCSFile(data?.uploadImage),
                    metaData: data?.uploadImage.metaData,
                  },
                };
              }
              return item;
            })
          );

          setUploading(uploading.filter((item) => item !== id));
        };

        function handleRemoveImage(item: ImageGalleryItem) {
          const newItems = renderProps.value.filter(
            (image) => image.id !== item.id
          );
          renderProps.onChange(newItems);
          onRemoveImage?.(newItems, item);
        }

        function handleImageLoadComplete(id: string) {
          setLoaded(loaded.concat(id));
        }

        return (
          <ImageUpload {...renderProps} onChange={handleImageChange}>
            {({
              handleImageSelect,
              removeImage,
              hasImage,
              previewImageUrl,
            }) => {
              if (!renderProps.value?.length && emptyContentComponent) {
                return typeof emptyContentComponent === "function"
                  ? emptyContentComponent(handleImageSelect)
                  : emptyContentComponent;
              }
              return (
                <>
                  <Flex
                    mb={1}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    {title && (
                      <Flex flexDir={"column"} pr={2}>
                        <Text variant="regular" fontWeight="bold" color="N600">
                          {title}
                        </Text>
                        {description && (
                          <Text variant="small">{description}</Text>
                        )}
                      </Flex>
                    )}
                  </Flex>

                  <Stack gap={2} direction="horizontal" flexWrap="wrap">
                    {renderProps.value?.map((item: ImageGalleryItem) => {
                      const src = item.original?.src;

                      return (
                        <Flex
                          key={item.id}
                          bg="N100"
                          borderRadius="md"
                          overflow="hidden"
                          position="relative"
                          variant="center"
                          h={104}
                          style={{ aspectRatio }}
                        >
                          {loaded.includes(item.id) && (
                            <Flex
                              borderRadius="full"
                              background={"rgba(0,0,0,0.5)"}
                              position="absolute"
                              right={1}
                              top={1}
                              zIndex={1}
                              w={32}
                              h={32}
                              variant="center"
                              css={`
                                &:hover {
                                  cursor: pointer;
                                  background: rgba(0, 0, 0, 1);
                                }
                              `}
                              onClick={() => handleRemoveImage(item)}
                            >
                              <Icon icon={<X size={20} />} color="white" />
                            </Flex>
                          )}
                          <Spinner size={30} color="N400" />
                          {src && (
                            <NextImage
                              loading="lazy"
                              src={src}
                              alt={item.title}
                              fill={true}
                              style={{
                                objectFit: "contain",
                                objectPosition: "center",
                              }}
                              onLoad={() => handleImageLoadComplete(item.id)}
                            />
                          )}
                        </Flex>
                      );
                    })}

                    {uploading.map(() => {
                      <Flex
                        h={104}
                        style={{ aspectRatio }}
                        bg="N100"
                        borderRadius="md"
                        variant="center"
                        flexDir="column"
                        opacity={1}
                      >
                        <Spinner size={30} color="N400" />
                      </Flex>;
                    })}
                    <Flex
                      style={{ aspectRatio }}
                      h={104}
                      borderRadius="md"
                      border="1px dashed"
                      borderColor="N400"
                      variant="center"
                      flexDir="column"
                      css={`
                        &:hover {
                          cursor: pointer;
                          background-color: #f4f5f7;
                        }
                      `}
                      onClick={() => handleImageSelect()}
                    >
                      <Icon
                        icon={<Images size={24} weight="light" />}
                        color="N600"
                      />
                      <Text variant="small">Add photo</Text>
                    </Flex>
                  </Stack>
                </>
              );
            }}
          </ImageUpload>
        );
      }}
    />
  );
}
