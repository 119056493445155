import { TertiaryButton, PrimaryButton } from "flicket-ui";
import styled from "styled-components";

export type Props = { variant?: "primary" | "default" };

export default styled(TertiaryButton).attrs<Props>((p) => ({
  as: p.variant === "primary" ? PrimaryButton : TertiaryButton,
  borderColor: "transparent",
  color: p.variant === "primary" ? "white" : "N600",
  backgroundColor: p.variant === "primary" ? "P300" : "N100",
  whiteSpace: "nowrap",
}))<Props>`
  transition: all 0.12s ease-out;

  &:hover,
  &:focus {
    filter: brightness(97%);
  }

  &:active {
    transition-duration: 0;
    filter: brightness(90%);
  }
`;
