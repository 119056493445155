import * as yup from "yup";
import { replaceValuesWithPaths } from "~features/generalAdmissionEvent/form/schema.helpers";
import { ObjectSchemaDefinition } from "~features/generalAdmissionEvent/form/schema.types";
import { ImageElement } from "../Image";

export type FormValues = {
  image?: File | string;
  imageLink?: string;
};

export const buildInitialState = (image: ImageElement): FormValues => {
  return {
    image: image ? image.url : null,
    imageLink: image ? image.href : null,
  };
};

export const buildSchema = (image: ImageElement) => {
  const initialState = buildInitialState(image);
  const schema = yup.object<ObjectSchemaDefinition<FormValues>>().shape({
    image: yup
      .mixed()
      .default(initialState.image)
      .required("Please insert an image"),
    imageLink: yup.string().default(initialState.imageLink).nullable(),
  });

  const defaultValues = schema.getDefault();
  const formNames = replaceValuesWithPaths(defaultValues);

  return { schema, formNames, defaultValues };
};
