export enum PRIMARY_NAVIGATION_KEYS {
  EVENTS = "EVENTS",
  SEASONS = "SEASONS",
  MEMBERSHIPS = "MEMBERSHIPS",
  PACKAGES = "PACKAGES",
}

export const primaryNavigationConfig = {
  [PRIMARY_NAVIGATION_KEYS.EVENTS]: {
    tabKey: PRIMARY_NAVIGATION_KEYS.EVENTS,
    name: "Events",
    slugSegment: "events",
  },
  [PRIMARY_NAVIGATION_KEYS.SEASONS]: {
    tabKey: PRIMARY_NAVIGATION_KEYS.SEASONS,
    name: "Seasons",
    slugSegment: "seasons",
  },
  [PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS]: {
    tabKey: PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS,
    name: "Memberships",
    slugSegment: "memberships",
  },
  [PRIMARY_NAVIGATION_KEYS.PACKAGES]: {
    tabKey: PRIMARY_NAVIGATION_KEYS.PACKAGES,
    name: "Packages",
    slugSegment: "packages",
  },
} as const;
